var Tools = (function (module, $, undefined) {
    'use strict';

    var module = {

        // Bootstrap breakpoints
        breakpoints: {
            screenXs: 480,
            screenSm: 768,
            screenMd: 992,
            screenLg: 1200,
        },

        iOS: function() {
            return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        },

        // Clone height from $sourceElement to $targetElement
        cloneHeight: function($sourceElement, $targetElement) {
            if ($sourceElement === undefined || !$sourceElement instanceof $) {
                console.error('Must specify source jQuery element');
                return false;
            }
            if ($targetElement === undefined || !$targetElement instanceof $) {
                console.error('Must specify target jQuery element');
                return false;
            }
            $targetElement.height($sourceElement.height());
        },
    };

    // jQuery plugins
    $.fn.extend({

        // Assign height 100% to all element parents till html
        percentageHeight: function(height) {
            $(this).height(height+'%').parents().each(function(i, parent) {
                $(parent).css('height', '100%');
            });
        },

        // Find the maximum height
        // and apply it to all item siblings with the same class
        enforceRowHeight: function() {
            var $self = $(this);
            var maxHeight = 0;
            var className = '.'+$self.get(0).className;
            $self.css('height', 'auto');
            $self.siblings(className).css('height', 'auto');
            $self.imagesLoaded(function() {
                $self.siblings(className).each(function(i, sibling) {
                    if ($self.height() > maxHeight) {
                        maxHeight = $self.height();
                    }
                    if ($(sibling).height() > maxHeight) {
                        maxHeight = $(sibling).height();
                    }
                });
                $self.height(maxHeight).siblings(className).height(maxHeight);
            });
        },
    });

    // Feature detection
    $(document).ready(function() {
        if (module.iOS()) {
            $('html').addClass('ios');
        }
    });

    return module;

}(Tools || {}, jQuery));